



























import './styles/RespondInfo.scss';
import './styles/RespondInfoAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IRespond } from '@store/modules/project-responds/interfaces/Interfaces';
import { userNamespace } from '@store/modules/user';
import { IUserState } from '@store/modules/user/Interfaces';

@Component({
    name: 'RespondInfo',
})
export default class RespondInfo extends Vue {
    @userNamespace.Getter('userData') userData!: IUserState;
    @Prop({}) respond!: IRespond;

    get requestUrl() {
        // const API_URI = 'https://smarte.test/dacon/auth';  // для тестовых (новый)
        // const API_URI = 'https://test-smarte.flagstudio.ru/dacon/auth';  // для тестовых (новый)
        const API_URI = 'https://smartcomm.smrte.ru/dacon/auth';     // для прода

        const smartcomItemIdSplit = this.respond.linkToSmartcom.split('/');
        return `${API_URI}?token=${localStorage.getItem('token')}&order=${smartcomItemIdSplit[smartcomItemIdSplit.length-1]}`;
    }
}
